import React from "react"
import Content from "../Content"
import SE0 from "../SEO"
import { isEmpty, isBoolean, kebabCase, isNil } from "lodash"
import { Link } from "gatsby"
import Share from "../Share"
import Disqus from "../Disqus"
import PropTypes from "prop-types"
import HeroBanner from "../Hero"

function article_composed(author, date) {
  let article_subtitle

  if (author) {
    article_subtitle = author + " - " + date
  } else {
    article_subtitle = date
  }

  return article_subtitle
}

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
  author,
  date,
  subtitle,
  slug,
  hero_settings,
}) => {
  const PostContent = contentComponent || Content
  const article_subtitle = article_composed(author, date)

  return (
    <div>
      <SE0
        title={title}
        meta_title={meta_title}
        meta_desc={meta_desc}
        cover={cover}
        slug={slug}
      />
      <HeroBanner
        title={title}
        subtitle={article_subtitle}
        hero_settings={hero_settings}
        cover={cover}
      />
      <section className='section edge--top is-light-primary'>
        <div className='container'>
          <div className='content'>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h2 className='has-text-weight-semibold is-size-2'>Tags</h2>
                <ul className='taglist'>
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link
                        className='button is-info badge'
                        to={`/tags/${kebabCase(tag)}/`}
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <hr />
            <Share title={title} slug={slug} excerpt={meta_desc} />
            <hr />
            <Disqus title={title} slug={slug} />
          </div>
        </div>
      </section>
    </div>
  )
};

ArticleTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  cover: PropTypes.string,
  author: PropTypes.string,
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  hero_settings: PropTypes.array.isRequired,
}

export default ArticleTemplate
