import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import ArticleTemplate from '../components/ArticleTemplate'



const ArticlePage = ({data}) => {
  const {markdownRemark: post} = data

  console.log(data)

  return (
    <ArticleTemplate
      content={post.html}
      contentComponent={HTMLContent}
      cover={post.frontmatter.cover}
      meta_title={post.frontmatter.meta_title}
      meta_desc={post.frontmatter.meta_description}
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      author={post.frontmatter.author}
      date={post.frontmatter.date}
      slug={post.fields.slug}
      hero_settings={post.frontmatter.hero_settings}
    />
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        author
        cover
        meta_title
        meta_description
        tags
        hero_settings {
          size
          particle
          images
          css
          font_color
          title_align
        }
      }
    }
  }
`
